export type RootState = {};

export type ExchangePoint = {
  id?: string;
  type: string;
  method: string;
  fromJunction: string;
  toJunction?: string;
  siteId?: string;
  venueId?: string;
};

export type Site = {
  id?: string;
  name?: string;
  organizationId?: string;
  levels?: any[];
  images?: any[];
  customImages?: any[];
  features?: any[];
  exchangePoints?: any[];
};

export type SiteInVuex = Pick<Site, 'id' | 'name' | 'organizationId'> & { styles?: any };

export type Organization = {
  id?: string;
  name: string;
};

export type Venue = {
  id?: string;
  name?: string;
  description?: string;
  organizationId?: string;
  sites?: Site[];
  siteIds?: string[];
  styles?: {
    standard?: string;
    light?: string;
    dark?: string;
  };
  webhookUrl?: string;
  webhookApiKey?: string;
  lastWebhookResponse?: {
    statusCode: number;
    message: string;
  };
  data?: {
    modified: boolean;
    sites?: Site[];
    exchangePoints?: ExchangePoint[];
  };
  basemap?: BaseStyle;
};

export type RasterSource = {
  _id?: string;
  name: string;
  url: string;
  description?: string;
};

export enum BaseStyle {
  Standard = 'standard',
  Dark = 'dark',
  Light = 'light',
  Streets = 'streets'
}

export type Zone = {
  id?: string;
  levelId?: string;
  feature: ZoneFeature;
};

export type ZoneFeature = {
  id?: string;
  type: string;
  geometry: {
    type: string;
    coordinates: Array<Array<Array<number>>>;
  };
  properties: ZoneProperties;
};

export type ZoneProperties = {
  id?: string;
  levelId?: string;
  color: string;
  opacity: number;
  displayName?: string;
  persistentId?: string;
};
